/**
 * Retrieves the dimensions of the device's screen.
 *
 * @returns {Object} An object containing the screen width and height.
 * @returns {number} return.screenWidth - The width of the screen in pixels.
 * @returns {number} return.screenHeight - The height of the screen in pixels.
 */
export const getDeviceDimensions = () => ({
  screenWidth: window.screen.width,
  screenHeight: window.screen.height,
});
