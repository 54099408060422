import React, { FC, useContext, MouseEvent as ReactMouseEvent } from 'react';
import { INav } from './Nav.def';
import { Link } from 'gatsby';
import MenuCheckBox from '../base/MenuCheckBox/MenuCheckBox';
import { handleNavLinkOnClick } from '../../utils/helpers/navLinkOnClickHandlers';
import HTEventsContext from '../../context/HTEventsContext';
import SubNavList from '../SubNavList';

const Nav: FC<INav> = ({ headerLinks, megaMenuHtImpressionConfig }) => {
  const htevents = useContext(HTEventsContext);

  return (
    <nav className="headerNav">
      <ul>
        {headerLinks?.length &&
          headerLinks.map(({ link, text, subMenu }, index) => {
            const trackText = text || 'unknown';
            const validLink = link || '#';
            return (
              <li key={index}>
                <Link
                  onClick={(event: ReactMouseEvent<Element, MouseEvent>) =>
                    handleNavLinkOnClick(event, htevents, validLink, trackText, trackText)
                  }
                  to={validLink}
                  onMouseOver={(e) => {
                    const dropdownEl = e.currentTarget.parentElement?.querySelector('.hover-off');
                    if (dropdownEl) {
                      dropdownEl.classList.remove('hover-off');
                    }
                  }}
                  className={`${subMenu?.categories ? 'desktop-dropdown-menu' : ''}`}
                >
                  {text}
                </Link>
                {subMenu?.categories && (
                  <>
                    <MenuCheckBox
                      className="sub-menu-item"
                      titleText={trackText}
                      sendHtImpression
                    />
                  </>
                )}
                {subMenu?.categories && (
                  <SubNavList
                    categories={subMenu?.categories}
                    title={trackText}
                    key={index}
                    megaMenuHtImpressionConfig={megaMenuHtImpressionConfig}
                  />
                )}
              </li>
            );
          })}
      </ul>
    </nav>
  );
};

export default Nav;
