import React, { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import CardDataProvider from '../providers/CardDataProvider';
import Footer from '../components/Footer';
import { getMenuItems } from '../utils/static-queries';
import TrackingWrapper from '../wrappers/TrackingWrapper';
import DisclosureModal from '../components/DisclosureModal/DisclosureModal';
import GlobalPostProvider from '../providers/GlobalPostProvider';
import { isCreditCardsReviewPage, isPreview } from '../utils';
import AdminBar from '../components/AdminBar/AdminBar';
import { ModalProvider } from '../providers/ModalProvider';
import useIsMobile from '../hooks/useIsMobile';
import HelloBar from '../components/HelloBar';
import DOMEffectWrapper from '../wrappers/DOMEffectWrapper';
import { searchInToggleElements } from '../utils/helpers/toggleElements';
import { isLocalStorageAllowed } from '../utils/helpers/localStorage';
import PulsePopupContainer from '../containers/PulsePopupContainer';
import CCMProvider from '../providers/CCMProvider';
import HTEventsProvider from '../providers/HTEventsProvider';
import { PageHistoryProvider } from '../providers/PageHistoryProvider';
import HeaderWrapper from '../wrappers/HeaderWrapper/HeaderWrapper';
import { IGatsbyPageContext } from '../models/IGatsbyPageContext';
import { getMegaMenuHtImpressionConfig } from '../utils/getMegaMenuHtImpressionConfig';

interface ChildProps {
  children: JSX.Element;
  pageContext: IGatsbyPageContext;
}

const Layout = ({ children, pageContext }: ChildProps) => {
  const isMobile = useIsMobile('phone');
  const isTabletOrMobile = useIsMobile();
  const { footerLinks, legalLinks, socialLinks, siteLine, mainMenuLinks, advertiserDisclosure } =
    getMenuItems();
  const megaMenuHtImpressionConfig = getMegaMenuHtImpressionConfig();
  const templateName = pageContext.templateName;
  const isPaidLander = ['Default', 'FullWidth', 'WithTopNav'].includes(templateName);
  const showHeader = isPaidLander ? templateName === 'WithTopNav' : true;
  const customHeaderClass = !showHeader ? 'template-lander-header' : null;

  useEffect(() => {
    if (isLocalStorageAllowed()) {
      const token = window.localStorage.getItem('token');
      const path = window.location.pathname;
      if (path !== '/preview/' && token) {
        window.localStorage.removeItem('token');
      }
    }
  }, []);

  const { toggleElements } = pageContext || {};
  const isDisabledOptin = searchInToggleElements(
    { toggleElements },
    '_upgp_toggle_elements_disable_optin',
  );

  return (
    <HTEventsProvider>
      <GoogleReCaptchaProvider reCaptchaKey={process.env['GATSBY_RECAPTCHA_SITE_KEY'] || ''}>
        <AdminBar wpUrl={process.env['GATSBY_WORDPRESS_URL']} />
        <HelloBar context={pageContext} />
        <HeaderWrapper
          headerLinks={mainMenuLinks}
          advertiserDisclosure={advertiserDisclosure}
          showContentLinks={showHeader}
          customHeaderClass={customHeaderClass}
          isTabletOrMobile={isTabletOrMobile}
          megaMenuHtImpressionConfig={megaMenuHtImpressionConfig}
        />
        <DisclosureModal />
        <main>
          <CCMProvider>
            <CardDataProvider>
              <PageHistoryProvider>
                <TrackingWrapper>
                  <GlobalPostProvider postDatabaseId={pageContext.databaseId}>
                    <ModalProvider>
                      <DOMEffectWrapper isMobile={isMobile} pageContext={pageContext}>
                        {children}
                      </DOMEffectWrapper>
                    </ModalProvider>
                  </GlobalPostProvider>
                </TrackingWrapper>
              </PageHistoryProvider>
            </CardDataProvider>
          </CCMProvider>
        </main>
        <Footer
          footerLinks={footerLinks}
          legalLinks={legalLinks}
          siteLine={siteLine}
          socialFooterLinks={socialLinks}
          pageId={pageContext?.databaseId}
        />
        {!isDisabledOptin &&
          !isTabletOrMobile &&
          !isPaidLander &&
          typeof location !== 'undefined' &&
          !isCreditCardsReviewPage(location.pathname) &&
          !isPreview(location.pathname) && <PulsePopupContainer context={pageContext} />}
      </GoogleReCaptchaProvider>
    </HTEventsProvider>
  );
};

export default Layout;
