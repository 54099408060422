import React, { FC, useContext, MouseEvent as ReactMouseEvent } from 'react';
import { IMenuCheckBox } from './MenuCheckBox.def';
import HTEventsContext from '../../../context/HTEventsContext';
import { sendHtTrackEvent } from '../../../utils/helpers/htApi';
import { HT_TRACK_EVENTS } from '../../../constants';

const MenuCheckBox: FC<IMenuCheckBox> = ({
  className,
  titleText,
  sendHtImpression = false,
  category,
  subCategory,
  ...rest
}) => {
  const htevents = useContext(HTEventsContext);

  const handleClick = (event: ReactMouseEvent<HTMLInputElement>) => {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked && sendHtImpression) {
      // Send an impression event if opening the menu and sendHtImpression is true
      sendHtTrackEvent(htevents, HT_TRACK_EVENTS.IMPRESSION, {
        element: 'Mega Menu',
        category: titleText,
      });
    }
    if (isChecked && className === 'sub-menu-item' && category && subCategory) {
      sendHtTrackEvent(htevents, HT_TRACK_EVENTS.IMPRESSION, {
        element: 'Mega Menu',
        category,
        subCategory,
      });
    }
  };

  return (
    <input
      {...rest}
      type="checkbox"
      aria-hidden="true"
      className={`mobile-menu-toggle ${className ? className : ''}`}
      onClick={handleClick}
    />
  );
};

export default MenuCheckBox;
