import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import SearchInput from '../SearchInput/SearchInput';
import SearchIcon from '../../../svgs/SearchIcon';
import { gql, useLazyQuery } from '@apollo/client';
import { ISearchWrapper } from './SearchWrapper.def';
import useIsMobile from '../../../hooks/useIsMobile';
import Loading from '../Loading/Loading';

const SearchWrapper: FC<ISearchWrapper> = ({ isDisabled = false }) => {
  const [isActive, setIsActive] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [lastSearchedText, setLastSearchedText] = useState('');
  const isMobile = useIsMobile();
  const [searchResults, setSearchResults] = useState<ISearchResult[]>([]);
  const [fetchPosts, { data, loading, error }] = useLazyQuery(
    gql`
      query ($searchTerm: String) {
        posts(where: { search: $searchTerm }, first: 10) {
          edges {
            node {
              id
              title
              uri
            }
          }
        }
      }
    `
  );

  useEffect(() => {
    if (isMobile) {
      if (isActive) {
        document.body.classList.add('body--overflow-hidden');
      } else {
        document.body.classList.remove('body--overflow-hidden');
      }
    }
  }, [isActive]);

  const executeSearch = () => {
    fetchPosts({
      variables: {
        searchTerm: searchText,
      },
    });
    setLastSearchedText(searchText);
  };

  const handleToggleButtonSearch = () => {
    setIsActive(!isActive);
  };

  const renderSearchContainer = (children: ReactNode) => {
    return (
      <>
        <button aria-label="Toggle search area off" disabled={isDisabled} onClick={handleToggleButtonSearch}>
          <SearchIcon />
        </button>
        <div className="search-wrapper">
          <div className="results-container">
            <SearchInput
              setSearchText={setSearchText}
              searchText={searchText}
              handleToggleButtonSearch={handleToggleButtonSearch}
              autoFocus={true}
              executeSearch={executeSearch}
            />
            {children}
          </div>
          <span className="closeSearch" onClick={handleToggleButtonSearch}>
            Close search
          </span>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (data && !loading) {
      const posts: Queries.WpPostConnection = data.posts;
      setSearchResults(
        posts.edges.map(({ node }) => {
          const { id, title, uri } = node;
          return {
            id,
            title: title || '',
            uri: uri || '#',
          };
        })
      );
    }
  }, [data, loading]);

  useEffect(() => {
    setSearchResults([]);
  }, [searchText]);

  if (loading) {
    return renderSearchContainer(<Loading />);
  }

  if (error) {
    return renderSearchContainer(
      <div className="search-container">
        <h4 className="error">Something went wrong, please try again.</h4>
      </div>
    );
  }

  return (
    <>
      {isActive ? (
        <>
          {renderSearchContainer(
            <>
              {searchResults.length > 0 ? (
                searchResults.map(({ id, title, uri }) => {
                  return (
                    <Link
                      onClick={() => {
                        setIsActive(false);
                        setSearchText('');
                      }}
                      key={id}
                      to={uri}
                      className="search-result-item"
                    >
                      {title}
                    </Link>
                  );
                })
              ) : (
                <>
                  {searchText.length > 2 && lastSearchedText === searchText && !loading && (
                    <h4>No matches found, please try another search.</h4>
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <button aria-label="Toggle search area on" disabled={isDisabled} onClick={handleToggleButtonSearch}>
          <SearchIcon />
        </button>
      )}
    </>
  );
};

export default SearchWrapper;
