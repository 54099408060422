import React, { FC } from 'react';

import { renderCTAButtonShortcode } from '@throttleup/esi-components';
import HTMLContentESIWrapper from '../../wrappers/HTMLContentESIWrapper';
import { getSiteDomainWithoutProtocol } from '../../utils/helpers/replaceDomain';
import { WO_AB_TEST_NAME } from '../../constants';

interface ILearnMoreESI {
  src: string;
  goLinkPlacing: string;
}

const LearnMoreESI: FC<ILearnMoreESI> = ({ src, goLinkPlacing }) => {
  const { searchParams } = new URL(`https://example.com${src}`);
  const cardId = parseInt(searchParams.get('card_id') || '0');
  const parentId = parseInt(searchParams.get('parent_id') || '0');
  const disableButton = searchParams.get('disable_button') === 'true';
  const overrideAffiliate = searchParams.get('override_affiliate') === 'true';
  const containerId = `learn_more_${cardId}`;
  const abTests = [WO_AB_TEST_NAME];
  const extraParams = [parentId, disableButton, overrideAffiliate, getSiteDomainWithoutProtocol()];

  return (
    <HTMLContentESIWrapper
      cardId={cardId}
      containerId={containerId}
      src={src}
      fallbackRenderFunction={renderCTAButtonShortcode}
      extraParams={extraParams}
      goLinkPlacing={goLinkPlacing}
      abTests={abTests}
    />
  );
};

export default LearnMoreESI;
