
import React from 'react';
import OrangeBadge from "../../components/base/OrangeBadge/OrangeBadge";
import RedBadge from '../../components/base/RedBadge/RedBadge';
import { ICardBadges } from '../../models/ICardBadges';


export const renderBadges = (badges: ICardBadges) => {
  // Render red badge if its value exists. It has higher priority.
  if (badges?.redBadgeText) {
    return <RedBadge redBadgeText={badges.redBadgeText} />;
  }
  // Render orange badge if its value exists, but only if no red badge is present.
  if (badges?.orangeBadgeText) {
    return <OrangeBadge orangeBadgeText={badges.orangeBadgeText} />;
  }
  // Return null if neither badge has value.
  return null;
};