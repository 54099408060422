import React, { FC, useContext, useEffect, useRef } from 'react';
import { ISubNavList } from './SubNavList.def';
import SubNav from '../SubNav/SubNav';
import HTEventsContext from '../../context/HTEventsContext';
import { sendHtTrackEvent } from '../../utils/helpers/htApi';
import useIsMobile from '../../hooks/useIsMobile';
import { HT_TRACK_EVENTS } from '../../constants';

const SubNavList: FC<ISubNavList> = ({ categories, title, megaMenuHtImpressionConfig }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  let timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);
  const htevents = useContext(HTEventsContext);
  const isMobile = useIsMobile();
  const { mainMenuHtImpressionDelay = 0, subMenuHtImpressionDelay = 0 } =
    megaMenuHtImpressionConfig;

  useEffect(() => {
    const dropDownItem = dropdownRef.current;
    let observer = null;

    if (htevents && !isMobile) {
      observer = new IntersectionObserver(
        ([entry]) => {
          if (entry?.isIntersecting) {
            // Element is visible, start the timeout
            timeoutId.current = setTimeout(() => {
              // Send impression event when the dropdown becomes visible
              sendHtTrackEvent(htevents, HT_TRACK_EVENTS.IMPRESSION, {
                element: 'Mega Menu',
                category: title,
              });
            }, mainMenuHtImpressionDelay); // Main menu impression event delay set in BE
          } else {
            // Element is no longer visible, clear the timeout
            if (timeoutId.current) clearTimeout(timeoutId.current);
          }
        },
        { threshold: 0 }, // Trigger the callback as soon as any part of the target is visible.
      );

      if (dropDownItem) {
        observer.observe(dropDownItem);
      }
    }

    // Cleanup observer on component unmount
    return () => {
      if (dropDownItem && observer) {
        observer.unobserve(dropDownItem);
        if (timeoutId.current) clearTimeout(timeoutId.current); // Clear any running timeouts
      }
    };
  }, [htevents, isMobile]);

  return (
    <div className="dropdown" ref={dropdownRef}>
      {categories.map((category, index) => (
        <SubNav
          className="dropdownCategory"
          category={category}
          parentCategory={title}
          key={index}
          subMenuHtImpressionDelay={subMenuHtImpressionDelay}
        />
      ))}
    </div>
  );
};

export default SubNavList;
