import React, { FC } from 'react';
import GreenCheck from '../../svgs/GreenCheck';
import { IHomeIntro } from './HomeIntro.def';
import { createSourceElements, createSrc, createSrcSet } from '../../utils';
import { MOBILE_MAX_WIDTH_MQ, SMALL_TAB_MIN_WIDTH_MQ } from '../../constants';
import useIsMobile from '../../hooks/useIsMobile';

const HomeIntro: FC<IHomeIntro> = ({ featuredImages, perks, title }) => {
  const isMobile = useIsMobile('phone');

  const getWidthAndHeight = (index: number) => {
    if (isMobile) {
      switch (index) {
        case 0:
          return {
            width: 200,
            height: 181,
          };
        case 1:
          return {
            width: 180,
            height: 164,
          };
        case 2:
          return {
            width: 170,
            height: 155,
          };
      }
    } else {
      switch (index) {
        case 0:
          return {
            width: 280,
            height: 254,
          };
        case 1:
          return {
            width: 219,
            height: 199,
          };
        case 2:
          return {
            width: 280,
            height: 254,
          };
      }
    }
    return {
      width: 280,
    };
  };

  return (
    <section className="homeIntro">
      <div className="container">
        <div className="content">
          <h1>{title}</h1>
          <ul>
            {perks?.map(({ description, title }, index) => {
              return (
                <li key={index}>
                  <GreenCheck />
                  <span>
                    <h2>{title}</h2>
                    <p>{description}</p>
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="featuredImages">
          {featuredImages.map((featuredImage, index) => {
            const sourceElements = createSourceElements([
              {
                media: MOBILE_MAX_WIDTH_MQ,
                srcSet: createSrcSet(featuredImage.src || '', [
                  {
                    options: {
                      width: 500,
                    },
                  },
                ]),
              },
              {
                media: SMALL_TAB_MIN_WIDTH_MQ,
                srcSet: createSrcSet(featuredImage.src || '', [
                  {
                    options: {
                      width: 350,
                      height: 318,
                    },
                  },
                ]),
              },
            ]);

            featuredImage = {
              ...featuredImage,
              src: createSrc(featuredImage.src || ''),
              ...getWidthAndHeight(index),
            };
            return (
              <figure key={`featuredImage${index}`} className={`featuredImage${index}`}>
                <picture>
                  {sourceElements}
                  <img {...featuredImage} />
                </picture>
              </figure>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default HomeIntro;
