import { graphql, useStaticQuery } from 'gatsby';

const getHightouchImpressionEventDelays = () => {
  const data = useStaticQuery(graphql`
    query GetHightouchImpressionEventDelays {
      allWpUpOption(filter: { slug: { eq: "hightouch-impression-event-delays" } }) {
        nodes {
          hightouchImpressionEventDelays {
            upgpImpressionEvents {
              elementName
              delayMs
            }
          }
        }
      }
    }
  `);
  return (data.allWpUpOption || []) as Queries.WpUpOptionConnection;
};

export default getHightouchImpressionEventDelays;
