import React, {
  FC,
  useContext,
  MouseEvent as ReactMouseEvent,
  MouseEventHandler as ReactMouseEventHandler,
  useRef,
} from 'react';
import { Link } from 'gatsby';
import { ISubNav } from './SubNav.def';
import parser from 'html-react-parser';
import MenuCheckBox from '../base/MenuCheckBox/MenuCheckBox';
import { handleNavLinkOnClick } from '../../utils/helpers/navLinkOnClickHandlers';
import HTEventsContext from '../../context/HTEventsContext';
import { sendHtTrackEvent } from '../../utils/helpers/htApi';
import { HT_TRACK_EVENTS } from '../../constants';
import useIsMobile from '../../hooks/useIsMobile';

const SubNav: FC<ISubNav> = ({ className, category, parentCategory, subMenuHtImpressionDelay }) => {
  const htevents = useContext(HTEventsContext);
  const hoverTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isMobile = useIsMobile();

  const { links, allLink, layout, title } = category;
  let ulProps: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLUListElement>,
    HTMLUListElement
  > = {};

  if (layout === 'sideBySide') {
    ulProps = {
      ...ulProps,
      className: 'sideBySide',
    };
  }

  const lowercaseVs = (str: string): string => {
    const replacement = '<span class="versus">vs.</span>';
    return str.replace('vs.', replacement);
  };

  const handleMouseEnter: ReactMouseEventHandler<HTMLDivElement> = () => {
    if (!isMobile) {
      hoverTimeout.current = setTimeout(() => {
        sendHtTrackEvent(htevents, HT_TRACK_EVENTS.IMPRESSION, {
          element: 'Mega Menu',
          category: parentCategory,
          subCategory: title.text,
        });
      }, subMenuHtImpressionDelay);
    }
  };

  const handleMouseLeave: ReactMouseEventHandler<HTMLDivElement> = () => {
    if (hoverTimeout.current) clearInterval(hoverTimeout.current);
  };

  return (
    <>
      <div
        className={`dropdown-subnav ${className ? className : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <p className="submenu-title">{title.text}</p>
        <MenuCheckBox
          className="sub-menu-item"
          category={parentCategory}
          subCategory={title.text}
        />
        <ul className="mobile-sub-menu-item__menu" {...ulProps}>
          {links.map(({ text, link }, index) => (
            <li key={index}>
              <Link
                onClick={(event: ReactMouseEvent<Element, MouseEvent>) =>
                  handleNavLinkOnClick(event, htevents, link, text, parentCategory, title.text)
                }
                to={link}
              >
                {parser(lowercaseVs(text))}
              </Link>
            </li>
          ))}
        </ul>
        {allLink && (
          <Link
            onClick={(event: ReactMouseEvent<Element, MouseEvent>) =>
              handleNavLinkOnClick(event, htevents, allLink.link, allLink.text)
            }
            className="allLink"
            to={allLink.link}
          >
            {allLink.text}
          </Link>
        )}
      </div>
    </>
  );
};

export default SubNav;
