import React, { FC, useEffect } from 'react';
import { Link } from 'gatsby';
import { createSourceElements, createSrc, createSrcSet } from '../../utils';
import { MOBILE_MAX_WIDTH_MQ, SMALL_TAB_MIN_WIDTH_MQ } from '../../constants';
import { IReviewTile } from './ReviewTile.def';
import { processSimpleHTMLContent } from '../../utils/processHTMLContent';
import { renderBadges } from '../../utils/helpers/badges';

const ReviewTile: FC<IReviewTile> = ({ thumbnail, title, summary, excerpt, badges }) => {
  if (!title) {
    return null;
  }
  const { link, text = '' } = title;

  useEffect(() => {
    const cardTitle = document.querySelector('.cardTitle');

    if (cardTitle && text && text.length >= 23) {
      cardTitle.classList.replace('cardTitle', 'cardTitleIncreasedHeight');
    }
  }, []);

  const sourceElements = createSourceElements([
    {
      media: MOBILE_MAX_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail?.src || '', [
        {
          options: {
            width: 500,
            fit: 'bounds',
          },
        },
      ]),
    },
    {
      media: SMALL_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail?.src || '', [
        {
          options: {
            width: 300,
            height: 161,
            fit: 'bounds',
          },
        },
      ]),
    },
  ]);

  thumbnail = {
    ...thumbnail,
    src: createSrc(thumbnail?.src || ''),
  };

  const redBadgeText = badges?.redBadgeText;
  const orangeBadgeText = badges?.orangeBadgeText;
   
  return (
    <article className="cardBlock">
      {(redBadgeText || orangeBadgeText) && (
        <div className="card-badges">
           {renderBadges({ redBadgeText, orangeBadgeText })}
        </div>
      )}
      <header>
        <figure>
          {link && (
            <Link to={link}>
              <picture>
                {sourceElements}
                <img {...thumbnail} loading="lazy" />
              </picture>
            </Link>
          )}
        </figure>
        <h3>
          {link && (
            <Link className="cardTitle" to={link}>
              {text}
            </Link>
          )}
        </h3>
      </header>
      {summary && <p>{processSimpleHTMLContent(summary)}</p>}
      {excerpt && <p className="card__excerpt">{excerpt}</p>}
    </article>
  );
};

export default ReviewTile;
