import { IMegaMenuHtImpressionEventDelay } from '../models/IMegaMenuHtImpressionEventDelay';
import { MEGA_MENU_MAIN_ELEMENT, MEGA_MENU_SUB_ELEMENT } from '../constants';
import getHightouchImpressionEventDelays from './static-queries/getHightouchImpressionEventDelays';
export const getMegaMenuHtImpressionConfig = () => {
  const allWpUpOption = getHightouchImpressionEventDelays();
  let megaMenuHtImpressionConfig: IMegaMenuHtImpressionEventDelay = {};
  allWpUpOption?.nodes?.forEach((node) => {
    if (node.hightouchImpressionEventDelays) {
      const { hightouchImpressionEventDelays } = node;
      hightouchImpressionEventDelays.upgpImpressionEvents?.forEach((upImpressionEvent) => {
        if (
          upImpressionEvent?.elementName === MEGA_MENU_MAIN_ELEMENT &&
          upImpressionEvent.delayMs
        ) {
          megaMenuHtImpressionConfig.mainMenuHtImpressionDelay = upImpressionEvent.delayMs;
        } else if (
          upImpressionEvent?.elementName === MEGA_MENU_SUB_ELEMENT &&
          upImpressionEvent.delayMs
        ) {
          megaMenuHtImpressionConfig.subMenuHtImpressionDelay = upImpressionEvent.delayMs;
        }
      });
    }
  });

  return megaMenuHtImpressionConfig;
};
